import React from 'react';
import { string } from 'prop-types';
import { ReactNode } from 'react';
import { theme } from '../../components/Shared/Theme/Theme';
import { styled } from 'linaria/react';

export interface TrainingTime {
  day: string;
  time: string;
  note: string;
}

export interface Training {
  place: string;
  address: string;
  note?: ReactNode;
  justify?: 'space-between' | 'space-evenly' | 'flex-start';
  times: TrainingTime[];
}

export type Trainings = Array<Training>;

// const TCALink = styled.div`
//   span {
//     color: ${theme.color.primaryDark};
//   }
//   a {
//     color: ${theme.color.primary};
//   }
// `;

export const trainings: Trainings = [
  {
    place: 'Aircraft Sport House',
    address: 'Ivánska cesta 30D, 821 04, Bratislava',
    justify: 'flex-start',
    // note: (
    //   <TCALink>
    //     <span>Krav Maga - registrácia možná</span> <a href="#">TU</a>
    //   </TCALink>
    // ),
    times: [
      {
        day: 'Utorok',
        time: '18:00 - 19:00',
        note: ''
      },
      {
        day: 'Štvrtok',
        time: '18:00 - 19:00',
        note: ''
      },
      {
        day: 'Podľa dohody',
        time: 'Individuálne tréningy',
        note: ''
      }
    ]
  },
  {
    place: 'Monkeyfit',
    address: 'Hauptplatz 37, 2421, Kittsee',
    note: 'Krav Maga Warrior AT',
    times: [
      {
        day: 'Pondelok',
        time: '19:30 - 20:30',
        note: ''
      },
      {
        day: 'Streda',
        time: '19:30 - 20:30',
        note: ''
      },
      {
        day: 'Podľa dohody',
        time: 'Individuálne tréningy',
        note: ''
      }
    ]
  },
  {
    place: 'Základná škola Kuchyňa',
    address: 'Kuchyňa 551, 900 52 Kuchyňa, Slovakia',
    times: [
      {
        day: 'Podľa dohody',
        time: 'Individuálne tréningy',
        note: ''
      },
      {
        day: 'Štvrtok',
        time: '18:00 - 19:15',
        note: ''
      }
    ]
  }
];
