import { styled } from 'linaria/react';
import React, { ReactNode } from 'react';
import { Note } from '../Note/Note';
import { muiTheme } from '../Theme/Theme';
import { HeadingThree } from '../Typography/HeadingThree/HeadingThree';
import { HeadingTwo } from '../Typography/HeadingTwo/HeadingTwo';

export interface HeadingWithNoteProps {
  className?: string;
  heading: ReactNode | string;
  note?: string;
}

const HeadingWithNoteInner = (props: HeadingWithNoteProps) => {
  const { heading, note } = props;
  return (
    <div className={props.className}>
      <HeadingThree className="heading dark">{heading}</HeadingThree>
      {note && <Note text={note} />}
    </div>
  );
};

export const HeadingWithNote = styled(HeadingWithNoteInner)`
  display: flex;
  padding-bottom: 28px;

  .heading {
    margin-right: 40px;
  }

  ${muiTheme.breakpoints.down('sm')} {
    flex-flow: column;
    .heading {
      margin-bottom: 10px;
      margin-right: 0px;
    }
  }
`;
