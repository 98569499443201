import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme/Theme';
import InfoIcon from '../../../assets/icons/kmw_icon_info.svg';

export interface NoteProps {
  className?: string;
  text: string;
}

const NoteInner = (props: NoteProps) => {
  const { text } = props;
  return (
    <div className={props.className}>
      <img src={InfoIcon}></img>
      <p>{text}</p>
    </div>
  );
};

export const Note = styled(NoteInner)`
  display: flex;
  text-align: left;
  align-items: center;

  img {
    max-height: 60px;
    max-width: 60px;
    object-fit: none;
    transition: all ${theme.hover.transitionTime}ms ease-in-out;
  }
  
  p {
    margin-left: 5px;
    font-size: 1.6rem;
    max-width: 500px;
    font-weight: 400;
    font-style: italic;
    display: flex;
    align-items: center;
  }
`;
