import { styled } from 'linaria/react';
import React from 'react';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { Gallery } from '../../Shared/Gallery/Gallery';
import { Section } from '../../Shared/Section/Section';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';
import { useTrainingImages } from './hooks/useTrainingImages';

export interface TrainingGallerySectionProps {
  className?: string;
}

const TrainingGallerySectionInner = (props: TrainingGallerySectionProps) => {
  const images = useTrainingImages();
  return (
    <Section className={props.className}>
      <ContainerCustom>
        <HeadingTwo className="center gallery-heading">
          FOTOGRAFIE Z TRÉNINGOV A PODUJATÍ
        </HeadingTwo>
        <Gallery images={images} />
      </ContainerCustom>
    </Section>
  );
};

export const TrainingGallerySection = styled(TrainingGallerySectionInner)`
  .gallery-heading {
    margin-bottom: 20px;
  }
`;
