import { styled } from 'linaria/react';
import { theme } from "../../Theme/Theme";

export const HeadingThree = styled.h3`
  text-transform: uppercase;
  font-size: 3.2rem;
  font-weight: 600;
  color: ${theme.color.secondary};
  padding: 8px 0;
  &.inverse{
    color: ${theme.color.light};
  }
  &.dark{
    color: ${theme.color.primaryDark};
  }
  &.center{
    text-align: center;
  }
`;