import { styled } from 'linaria/react';
import { muiTheme, theme } from '../Theme/Theme';

export const CornerBorder = styled.div`
  position: absolute;
  border-color: ${theme.color.primary};
  border-width: 3px;
  height: 50%;
  width: 15%;

  &.cb {
    &--top {
      top: -3px;
      border-top-style: solid;
    }
    &--bottom {
      bottom: -3px;
      border-bottom-style: solid;
    }
    &--left {
      left: -30px;
      border-left-style: solid;
    }
    &--right {
      right: -30px;
      border-right-style: solid;
    }
    &--full {
      height: 100%;
    }
    &--secondary {
      border-color: ${theme.color.gray};
      border-width: 3px;
      width: 30%;
    }

    ${muiTheme.breakpoints.down('sm')} {
      &--top {
        top: 0;
        border-top-style: solid;
      }
      &--bottom {
        bottom: 0;
        border-bottom-style: solid;
      }
      &--left {
        left: 0px;
        border-left-style: solid;
      }
      &--right {
        right: 0px;
        border-right-style: solid;
      }
      &--full {
        height: 100%;
      }
      &--secondary {
        border-color: ${theme.color.gray};
        border-width: 4px;
        width: 30%;
      }
    }
  }
`;
