import React, { ReactNode } from 'react';
import { styled } from 'linaria/react';
import { muiTheme, theme } from '../Theme/Theme';
import { HeadingFour } from '../Typography/HeadingFour/HeadingFour';
import { Hidden } from '@material-ui/core';

export interface PlaceLabelProps {
  className?: string;
  place: string;
  address: string;
  note?: ReactNode;
}

const Note = styled.p`
  color: #5c5c5c !important;
`

const Label = styled.div`

  ${muiTheme.breakpoints.down('sm')} {
    flex-flow: column
  }
`

const PlaceLabelInner = (props: PlaceLabelProps) => {
  const { place, address, note } = props;
  return (
    <div className={props.className}>
      <HeadingFour className="place">{place}</HeadingFour>
      <Hidden smDown>
        <p>{address}</p>
        {note && <Note>{note}</Note>}
      </Hidden>
    </div>
  );
};

export const PlaceLabel = styled(PlaceLabelInner)`
  display: flex;
  .place {
    margin-top: 7px;
  }
  p {
    color: ${theme.color.primary};
    font-size: 1.9rem;
    border-left: solid 3px ${theme.color.gray};
    margin: 8px 0 0 15px;
    padding: 7px 0 0 15px;
  }
`;
