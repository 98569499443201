import { useStaticQuery, graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';

export const useTrainingImages = (): FluidObject[] => {
  const {
    allFile: { nodes }
  } = useStaticQuery(graphql`
    query useTrainingImages {
      allFile(filter: { relativeDirectory: { eq: "images/trainings" } }) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 400, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `);

  return nodes.map(node => node.childImageSharp.fluid);
};
