import React from 'react';
import { BorderedCard } from '../BorderedCard/BorderedCard';
import { Grid } from '@material-ui/core';
import { styled } from 'linaria/react';
import { theme } from '../Theme/Theme';
import { TrainingTime } from '../../../assets/Content/useTrainings';
import { HeadingFour } from '../Typography/HeadingFour/HeadingFour';

export interface BorderedCardsBannerProps {
  bannerItems: TrainingTime[];
  justify?: 'space-between' | 'space-evenly' | 'flex-start';
  className?: string;
}

const BorderedCardsBannerInner = (props: BorderedCardsBannerProps) => {
  const { bannerItems, justify = 'space-between' } = props;
  return (
    <Grid container justify={justify} spacing={5}>
      {bannerItems.map((item: any, index) => (
        <Grid key={index} item xs={12} sm={4} className="bordered-card">
          <BorderedCard heading={item.day} info={item.time}/>
        </Grid>
      ))}
    </Grid>
  );
};

export const BorderedCardsBanner = styled(BorderedCardsBannerInner)`
  .bordered-card {
    max-width: 250px;
  }
`;
