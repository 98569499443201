export interface Price {
  price: number;
  name: string;
}

export const usePrices = (): Price[] => {
  return [
    {
      price: 12,
      name: 'Jednorázový vstup'
    },
    {
      price: 50,
      name:
        'Polovičná mesačná permanentka - 1x týždenne (Aircraft Sport House / Monkeyfit)'
    },
    {
      price: 80,
      name:
        'Mesačná permanentka - 2x týždenne (Aircraft Sport House / Monkeyfit)'
    }
  ];
};
