import { styled } from 'linaria/react';
import React from 'react';
import { HeadingThree } from '../../Shared/Typography/HeadingThree/HeadingThree';
import { Link } from 'gatsby';
import { routes } from '../../../lib/routes';
import { KVButton } from '../../Shared/KVButton/KVButton';
import { Arrow } from '../../Shared/Arrow/Arrow';
import { Horizontal } from '../../Shared/Arrow/styled';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';

export interface CtaSectionProps {
  className?: string;
}

const CtaSectionInner = (props: CtaSectionProps) => {
  return (
    <ContainerCustom>
      <div className={props.className}>
        <div className="cta__Section">
          <Horizontal />
          <Arrow />
          <HeadingThree className="heading dark">
            STAŇ SA KRAV MAGA WARRIOR!
          </HeadingThree>
          <Link to={routes.signUp.to}>
            <div className="button">
              <KVButton>ZAPÍSAŤ SA DO KMW</KVButton>
            </div>
          </Link>
        </div>
      </div>
    </ContainerCustom>
  );
};

export const CtaSection = styled(CtaSectionInner)`
  .heading {
    margin-top: 15px;
  }
  .button {
    margin-top: 15px !important;
    max-width: 270px;
    margin: auto;
  }
  position: relative;
  justify-content: center;
  text-align: center;
`;
