import React from 'react';
import Layout from '../components/Shared/Layout/Layout';
import { SEO } from '../components/Shared/SEO/SEO';
import { TrainingPlaces } from '../components/Shared/TrainingPlaces/TrainingPlaces';
import { TrainingEquipment } from '../components/Shared/TrainingEquipment/TrainingEquipment';
import { TrainingGallerySection } from '../components/Trainings/TrainingGallerySection/TrainingGallerySection';
import { TrainingPricesSection } from '../components/Trainings/TrainingPricesSection/TrainingPricesSection';
import { TrainingScheduleSection } from '../components/Trainings/TrainingScheduleSection/TrainingScheduleSection';
import { CtaSection } from '../components/Trainings/CtaSection/CtaSection';

const TreningyPage = () => {
  return (
    <Layout>
      <SEO title={'Tréningy'}/>
      <TrainingScheduleSection />
      <TrainingPricesSection />
      <TrainingPlaces />
      <TrainingEquipment />
      <CtaSection />
      <TrainingGallerySection />
    </Layout>
  );
};

export default TreningyPage;
