import { styled } from 'linaria/react';
import React from 'react';
import { theme } from '../../../Shared/Theme/Theme';
import { Price } from '../hooks/usePrices';

export interface TrainingPriceProps extends Price {
  className?: string;
}

const TrainingPriceInner = (props: TrainingPriceProps) => {
  const { price, name } = props;
  return (
    <div className={props.className}>
      <div className="training-price__price">{price}€</div>
      <div className="training-price__name">{name}</div>
    </div>
  );
};

export const TrainingPrice = styled(TrainingPriceInner)`
  display: flex;
  align-items: center;
  .training-price {
    &__name {
      color: ${theme.color.primaryDark};
      font-size: 2.2rem;
    }
    &__price {
      background: ${theme.color.primary};
      color: ${theme.color.background};
      min-height: 60px;
      min-width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
      font-size: 2.2rem;
    }
  }
`;
