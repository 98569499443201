import { styled } from 'linaria/react';
import React from 'react';
import { Borders } from '../../Shared/Borders/Borders';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { HeadingWithNote } from '../../Shared/HedingWithNote/HeadingWithNote';
import { Section } from '../../Shared/Section/Section';
import { TrainingPrice } from './components/TrainingPrice';
import { usePrices } from './hooks/usePrices';

export interface TrainingPricesSectionProps {
  className?: string;
}

const TrainingPricesSectionInner = (props: TrainingPricesSectionProps) => {
  const prices = usePrices();
  return (
    <Section className={props.className}>
      <ContainerCustom>
        <Borders topRight bottomRight>
          <div className="prices">
            <HeadingWithNote
              heading={'VSTUPNÉ A PREDPLATNÉ'}
              note="Individuálne tréningy vyžadujú individuálny prístup a cena je určená na základe vzájomnej dohody."
            />
            {prices.map(price => (
              <TrainingPrice
                className="training-price"
                key={price.name}
                {...price}
              />
            ))}
          </div>
        </Borders>
      </ContainerCustom>
    </Section>
  );
};

export const TrainingPricesSection = styled(TrainingPricesSectionInner)`
  .training-price {
    margin-bottom: 20px;
  }

  .prices {
    padding: 20px 0;
  }
`;
