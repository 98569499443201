import React from 'react';
import { styled } from 'linaria/react';
import { CornerBorder } from '../CornerBorder/CornerBorder';
import { muiTheme } from '../Theme/Theme';
import { Hidden } from '@material-ui/core';

export interface BordersProps {
  className?: string;
  children: JSX.Element | JSX.Element[];
  bottomRight?: boolean;
  topRight?: boolean;
  bottomLeft?: boolean;
  topLeft?: boolean;
}

const BordersInner = (props: BordersProps) => {
  const { topLeft, topRight, bottomLeft, bottomRight: btmRight } = props;
  return (
    <div className={props.className}>
      <Hidden smDown>
        {bottomLeft && (
          <CornerBorder className="cb--bottom cb--left cb--full" />
        )}
        {btmRight && <CornerBorder className="cb--bottom cb--right cb--full" />}
        {topLeft && <CornerBorder className="cb--top cb--left cb--full" />}
        {topRight && <CornerBorder className="cb--top cb--right cb--full" />}
      </Hidden>
      {props.children}
    </div>
  );
};

export const Borders = styled(BordersInner)`
  position: relative;
`;
