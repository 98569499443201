import { Grid, styled } from '@material-ui/core';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';

export interface GalleryProps {
  images: FluidObject[];
  className?: string;
}

const GalleryInner = (props: GalleryProps) => {
  const { images } = props;
  return (
    <div className={props.className}>
      <Grid container spacing={4}>
        {images.map((image, index) => (
          <Grid item key={index} xs={6} md={3}>
            <GatsbyImage fluid={image} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export const Gallery = GalleryInner;
