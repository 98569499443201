import { styled } from 'linaria/react';
import React from 'react';
import { trainings } from '../../../assets/Content/useTrainings';
import { CarouselItem } from '../../Landing/CarouselSection/components/CarouselItem';
import { BorderedCardsBanner } from '../../Shared/BorderedCardsBanner/BorderedCardsBanner';
import { Borders } from '../../Shared/Borders/Borders';
import { ContainerCustom } from '../../Shared/ConatinerCustom/ContainerCustom';
import { HeadingWithNote } from '../../Shared/HedingWithNote/HeadingWithNote';
import { PlaceLabel } from '../../Shared/PlaceLabel/PlaceLabel';
import { Section } from '../../Shared/Section/Section';
import { HeadingTwo } from '../../Shared/Typography/HeadingTwo/HeadingTwo';

export interface TrainingScheduleSectionProps {
  className?: string;
}

export const TrainingRow = styled.div`
  margin-bottom: 20px;
`;

const TrainingScheduleSectionInner = (props: TrainingScheduleSectionProps) => {
  return (
    <Section className={props.className}>
      <ContainerCustom>
        <HeadingTwo>Tréningy krav maga warrior</HeadingTwo>
        <Borders bottomLeft>
          <HeadingWithNote
            heading={'Pravidelné tréningy'}
            note="O ďalších tréningoch, ako aj o nepravidelných podujatiach a akciách
            sa dozvieš na našej Facebook fan page. "
          />
          {trainings.map(training => (
            <TrainingRow key={training.place}>
              <PlaceLabel
                className="trainings__place-label"
                place={training.place}
                address={training.address}
                note={training.note}
              />
              <BorderedCardsBanner
                justify={training.justify}
                bannerItems={training.times}
              />
            </TrainingRow>
          ))}
        </Borders>
      </ContainerCustom>
    </Section>
  );
};

export const TrainingScheduleSection = styled(TrainingScheduleSectionInner)`
  .trainings {
    &__place-label {
      margin-bottom: 20px;
    }
  }
`;
