import { styled } from 'linaria/react';
import { theme } from '../../Theme/Theme';

export const HeadingTwo = styled.h2`
  text-transform: uppercase;
  font-size: 4.2rem;
  font-weight: 600;
  color: ${theme.color.primary};
  padding-bottom: 30px;
  &.inverse {
    color: ${theme.color.light};
  }
  &.dark {
    color: ${theme.color.primaryDark};
  }
  &.center {
    text-align: center;
  }
`;
